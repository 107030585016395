import {Trans, withTranslation} from "react-i18next";
import * as Icon from "react-bootstrap-icons";
import {Container} from "react-bootstrap";
import Treatment from "./Treatment";

const Home = ({t}) => {
    return (
        <>
            <section>
                <div className="content-carousel">
                    <img className="d-block w-100" src="/images/home/home.webp" alt="Home"/>
                </div>
            </section>
            <section>
                <Container className="content-text text-center mt-3">
                    <h1 className="mt-3">
                        <Trans i18nKey="messages.page.home.h1"/>
                    </h1>
                    <div>
                        <div className="headline-container">
                            <div className="headline-div">
                                <span aria-hidden="true">
                                    <Icon.House className={"headline-icon"} size={"24"}/>
                                </span>
                            </div>
                        </div>
                        <h2 className="mt-3">
                            <Trans i18nKey="messages.page.home.welcome.title"/>
                        </h2>
                        <div className="mt-3">
                            <Trans i18nKey="messages.page.home.welcome.text"/>
                        </div>
                    </div>
                    <div>
                        <div className="headline-container">
                            <div className="headline-div">
                                <span aria-hidden="true">
                                    <Icon.List className={"headline-icon"} size={"24"}/>
                                </span>
                            </div>
                        </div>
                        <h2 className="mt-3">
                            <Trans i18nKey="messages.page.home.treatment.title"/>
                        </h2>
                        <div className="mt-3">
                            <Trans i18nKey="messages.page.home.treatment.text"/>
                        </div>
                        <Treatment/>
                    </div>
                    <div>
                        <div className="headline-container">
                            <div className="headline-div">
                                <span aria-hidden="true">
                                    <Icon.Phone className={"headline-icon"} size={"24"}/>
                                </span>
                            </div>
                        </div>
                        <h2 className="mt-3">
                            <Trans i18nKey="messages.page.home.contact.title"/>
                        </h2>
                        <div className="mt-3">
                            <Trans i18nKey="messages.page.home.contact.text"/>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default withTranslation()(Home);