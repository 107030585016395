import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
// import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector';
// import translationEN from '../public/locales/en/translation.json';
import translationDE from './locales/de-DE/translation.json';
import translationEN from './locales/en-EN/translation.json';
import translationRU from './locales/ru-RU/translation.json';
import translationUA from './locales/uk-UA/translation.json';

// the translations
const resources = {
    "en-EN": {
        translation: translationEN
    },
    "de-DE": {
        translation: translationDE
    },
    "ru-RU": {
        translation: translationRU
    },
    "uk-UA": {
        translation: translationUA
    }
};

i18next
    .use(LanguageDetector)
    // .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'de-DE', // use de-DE if detected lng is not available
        debug: false,
        // backend: {
        //     loadPath: '/messages_{{lng}}.json'
        // },
        interpolation: {
            escapeValue: false,
        },
        saveMissing: true, // send not translated keys to endpoint
        react: {
            // ...
            hashTransKey: function (defaultValue) {
                // return a key based on defaultValue or if you prefer to just remind you should set a key return false and throw an error
            },
            defaultTransParent: 'div', // a valid react element - required before react 16
            transEmptyNodeValue: '', // what to return for empty Trans
            transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'], // don't convert to <1></1> if simple react elements
            transWrapTextNodes: '', // Wrap text nodes in a user-specified element.
                                    // i.e. set it to 'span'. By default, text nodes are not wrapped.
                                    // Can be used to work around a well-known Google Translate issue with React apps. See: https://github.com/facebook/react/issues/11538
                                    // (v11.10.0)
        }
    });

export default i18next;