import './styles/App.scss';
import {Route, Routes} from 'react-router-dom';
import Layout from './components/layout/Layout';
import Home from './components/page/Home';
import Treatment from './components/page/Treatment';
import Contact from './components/page/Contact';
import About from './components/page/About';
import NoPage from './components/page/NoPage';

function App() {

    return (
        <div className="app-container">
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="treatment" element={<Treatment/>}/>
                    <Route path="contact" element={<Contact/>}/>
                    <Route path="about" element={<About/>}/>
                    <Route path="*" element={<NoPage/>}/>
                </Route>
            </Routes>
        </div>
    );
}

export default (App);