import {withTranslation} from "react-i18next";
import Products from "./treatment/Products";
import Container from "react-bootstrap/Container";

const Treatment = ({t}) => {

    const products = [
        {
            slug_text: 'gesicht',
            title: 'Gesicht',
            description: 'Gesichtsbehandlungen',
        },
        {
            slug_text: 'fusspflege',
            title: 'Fußpflege',
            description: 'Rund um Ihre Füße',
        },
        {
            slug_text: 'manikure',
            title: 'Maniküre',
            description: 'Für Ihre Hände',
        },
        {
            slug_text: 'waxing',
            title: 'Waxing',
            description: 'Haarentfernung mit Warmwachs',
        },
        {
            slug_text: 'wellness',
            title: 'Wellness',
            description: 'Wellness-Behandlungen',
        },
        {
            slug_text: 'makeup',
            title: 'Make-Up',
            description: 'Make-Up & Styling',
        },
        {
            slug_text: 'wimpern',
            title: 'Wimpern',
            description: 'Wimperndesign',
        },
        {
            slug_text: 'shellac',
            title: 'Shellac',
            description:
                'Für das absolute non plus Ultra-Nagelerlebnis führen wir SHELLAC!!! Die Innovation auf dem Nagelmarkt. SHELLAC ist ein normaler Nagellack welcher in UV-Licht sofort trocknet und bis zu 14 Tage makellos auf Ihren Fingern hält. Absolut kratzfest und hochglänzend!',
        },
    ];

    return (
        <Container className="content-text text-center mt-3">
            <Products products={products}/>
        </Container>
    );
};

export default withTranslation()(Treatment);