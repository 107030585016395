import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Trans, withTranslation} from 'react-i18next';

function Header({t, i18n}) {

    const navDropdownTitle = (
        <>
            <span className={"flag-icon flag-icon-" + i18n.resolvedLanguage}></span>&nbsp;{t(i18n.resolvedLanguage)}
        </>
    );

    return (
        <header>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                {/*<Container fluid>*/}
                <Container>
                    <Navbar.Brand href={"/"}>
                        <div className="site-logo">
                            <Trans i18nKey="messages.navigation.brand"/>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href={"/"}>
                                <Trans i18nKey="messages.navigation.home"/>
                            </Nav.Link>
                            <Nav.Link href={"/treatment"}>
                                <Trans i18nKey="messages.navigation.treatments"/>
                            </Nav.Link>
                            <Nav.Link href={"/about"}>
                                <Trans i18nKey="messages.navigation.about"/>
                            </Nav.Link>
                            <Nav.Link href="/contact">
                                <Trans i18nKey="messages.navigation.contact"/>
                            </Nav.Link>
                        </Nav>
                        {/*<Form className="d-flex">*/}
                        {/*    <Form.Control*/}
                        {/*        type="search"*/}
                        {/*        placeholder="Search"*/}
                        {/*        className="me-2"*/}
                        {/*        aria-label="Search"*/}
                        {/*    />*/}
                        {/*    <Button variant="outline-success">Search</Button>*/}
                        {/*</Form>*/}
                        <Nav>
                            <NavDropdown
                                id="nav-dropdown-dark-example"
                                title={navDropdownTitle}
                                menuVariant="dark"
                            >
                                <NavDropdown.Item onClick={() => i18n.changeLanguage('de-DE')}>
                                    <span className={"flag-icon flag-icon-de-DE"}></span>&nbsp;{t('de-DE')}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => i18n.changeLanguage('en-EN')}>
                                    <span className={"flag-icon flag-icon-en-EN"}></span>&nbsp;{t('en-EN')}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => i18n.changeLanguage('ru-RU')}>
                                    <span className={"flag-icon flag-icon-ru-RU"}></span>&nbsp;{t('ru-RU')}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => i18n.changeLanguage('uk-UA')}>
                                    <span className={"flag-icon flag-icon-uk-UA"}></span>&nbsp;{t('uk-UA')}
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default withTranslation()(Header);