import {withTranslation} from "react-i18next";
import Product from "./Product";
import {Col, Row} from "react-bootstrap";

function Products(props) {
    const products = props.products;

    return (
        <Row className="justify-content-center">
            <Col className="col-lg-2 col-md-3 col-sm-4 col-6">
                {products.map((product) =>
                    <Product product={product}/>
                )}
            </Col>
        </Row>
    );
}

export default withTranslation()(Products);