import {Trans, withTranslation} from "react-i18next";

const About = ({t}) => {
    return (
        <section>
            <div className="container content-text text-center mt-3">
                <h1>
                    <Trans i18nKey="messages.page.about.title"/>
                </h1>
                <div>
                    <Trans i18nKey="messages.page.about.text"/>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(About);