import {withTranslation} from "react-i18next";

const NoPage = ({t}) => {
    return (
        <section>
            <div className="container content-text text-center mt-3">
                <h1>
                    Not found
                </h1>
                <div>
                    <p>Oops, the page you're looking for wasn't found</p>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(NoPage);